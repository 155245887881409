import { useEffect } from "react";

const Red = () => {
    useEffect(() => {
        window.location.href =
            "https://grey-matters-registration.herokuapp.com/";
    });

    return "";
};

export default Red;
